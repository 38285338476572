<template>
  <general-card class="mt-7">
    <v-card-title>
      <v-row>
        <v-col>
          <h5>Facebook Adsets</h5>
        </v-col>
        <v-col cols="4">
          <v-select
            class="mr-4"
            outlined
            dense
            v-model="ad_account_id_"
            label="Change ad account"
            :items="ad_accounts"
            item-text="name"
            return-object
            hide-details="auto"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-select
            :items="facebook_campaigns"
            label="Select Campaign"
            outlined
            dense
            item-text="name"
            required
            :rules="[(v) => !!v || 'Campaign Is Required!']"
            v-model="selectedCampaign"
            item-value="id"
            @change="getAdsetsByCampaign($event)"
            :disabled="facebook_campaigns.length ? false : true"
            hide-details="auto"
          ></v-select>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider></v-divider>

    <v-data-table
      :headers="headers"
      :items="facebook_adsets"
      :loading="loading"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      hide-default-footer
    >
      <!-- eslint-disable-next-line-->
      <template v-slot:item.name="{ item }">
        <span class="font-weight-bold">{{ item.name }}</span>
      </template>
      <!-- eslint-disable-next-line-->
      <template v-slot:item.effective_status="{ item }">
        <v-chip
          :color="setStatusColor(item.effective_status)"
          :text-color="setTextStatusColor(item.effective_status)"
          class="font-weight-bold"
          small
        >
          <v-icon x-small left>mdi-circle</v-icon>
          <span class="text-capitalize">{{
            item.effective_status.replaceAll("_", " ").toLowerCase()
          }}</span>
        </v-chip>
      </template>
      <!-- eslint-disable-next-line-->
      <template v-slot:item.budget_remaining="{ item }">
        {{
          (item.budget_remaining / 100).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
        }}
      </template>
    </v-data-table>
    <v-divider></v-divider>

    <v-card-actions class="d-flex align-center pa-4">
      <div class="caption mr-3">
        {{ `Page: ` + page + ` of ` + pageCount }}
      </div>
      <v-select
        v-model="itemsPerPage"
        :items="[10, 25, 50, 100, 250]"
        label="Items per page"
        outlined
        dense
        hide-details
        @input="itemsPerPage = parseInt($event, 10)"
        class="shrink"
        :menu-props="{ top: true, offsetY: true }"
      ></v-select>

      <v-spacer></v-spacer>

      <v-pagination
        v-model="page"
        :length="pageCount"
        class="custom"
      ></v-pagination>
    </v-card-actions>

    <v-row>
      <v-col cols="5">
        <adset-widget :sideWidgetData="sideWidgetData" v-if="show" />
      </v-col>
    </v-row>
  </general-card>
</template>

<script>
import GeneralCard from "../../../components/Layouts/Cards/GeneralCard.vue";
import AdsetWidget from "../../../components/Facebook/AdsetWidget.vue";
import { facebookMixin } from "../../../mixins/facebookMixin";

export default {
  mixins: [facebookMixin],
  data: () => ({
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    adAccountId: "",
    ad_account_id_: "",
    show: false,
    loading: true,
    sideWidgetData: null,
    selectedCampaign: null,
    selected_adset: null,
    headers: [
      { text: "Ad name", value: "name" },
      { text: "Status", value: "effective_status" },
      { text: "Budget Remaining", value: "budget_remaining" },
    ],
  }),
  components: {
    GeneralCard,
    AdsetWidget,
  },
  computed: {
    currency() {
      return this.$store.state.facebook.ad_account.currency;
    },
    facebook_adsets() {
      return this.$store.state.facebook.facebook_adsets_by_campaign;
    },
  },
  mounted() {
    //OUTDATED CODE
    // this.ad_account_id_ = this.latest_ad_account
    //   ? this.latest_ad_account.id.substring(4)
    //   : "";

    if (typeof localStorage.getItem("ad_account") != undefined) {
      this.ad_account_id_ = JSON.parse(localStorage.getItem("ad_account"));
    }
  },
  watch: {
    facebook_campaigns() {
      this.setDefaultCampaign();
    },
    //Oudated code
    // async latest_ad_account() {
    //   const payload = {};
    //   payload.facebook_adsets_by_campaign = [];
    //   this.$store.commit("facebook/setFacebookAdsetsByCampaign", payload);

    //   this.ad_account_id_ = this.latest_ad_account.id;
    //   this.adAccountId = this.latest_ad_account.id.substring(4);
    //   await this.fetchCampaigns();
    //   await this.setDefaultCampaign();
    // },
    ad_account_id_() {
      const payload = {};
      payload.facebook_adsets_by_campaign = [];
      this.$store.commit("facebook/setFacebookAdsetsByCampaign", payload);

      //set account id on local session
      localStorage.setItem("ad_account", JSON.stringify(this.ad_account_id_));

      if (this.ad_account_id_) {
        let id = this.ad_account_id_.id;

        const sanitesed_id = id.includes("act_") ? id.substring(4) : id;

        this.adAccountId = sanitesed_id;

        this.fetchCampaigns(this.adAccountId);
      }
    },
    adAccountId() {
      const payload = {};
      payload.ad_account_id = this.adAccountId;
      this.$store.commit("facebook/setAdAccountId", payload);
    },
  },
  methods: {
    setStatusColor(status) {
      if (status === "WITH_ISSUES") return "red lighten-5";
      if (status === "IN_PROCESS") return "orange lighten-5";
      if (status === "PAUSED") return "blue lighten-5";
      if (status === "CAMPAIGN_PAUSED") return "blue lighten-5";
      if (status === "ACTIVE") return "green lighten-5";
      if (status === "ARCHIVED") return "grey lighten-5";
    },
    setTextStatusColor(status) {
      if (status === "WITH_ISSUES") return "red ";
      if (status === "IN_PROCESS") return "orange ";
      if (status === "PAUSED") return "blue ";
      if (status === "CAMPAIGN_PAUSED") return "blue";
      if (status === "ACTIVE") return "green ";
      if (status === "ARCHIVED") return "grey ";
    },
    displaySideWidget(adSet) {
      this.show = true;
      this.sideWidgetData = this.facebook_adsets.find(
        (adset) => adset.id == adSet.id
      );
    },
    setDefaultCampaign() {
      if (this.facebook_campaigns.length > 0) {
        this.selectedCampaign = this.facebook_campaigns[0];
        this.getAdsetsByCampaign(this.selectedCampaign.id);
      }
    },
    async getAdsetsByCampaign(id) {
      this.show = false;

      try {
        // this.$store.commit("makingApiRequest", true);
        this.loading = true;
        await this.$store.dispatch("facebook/getFacebookAdsetsByCampaign", {
          adAccountId: this.adAccountId,
          campaignId: id,
        });
        // this.$store.commit("makingApiRequest", false);
        this.loading = false;
      } catch (error) {
       this.error = error
        // this.$store.commit("makingApiRequest", false);
        this.loading = false;
      }
    },
  },
  beforeMount() {
    let payload = {};
    payload.facebook_adsets_by_campaign = [];
    this.$store.commit("facebook/setFacebookAdsetsByCampaign", payload);
  },
};
</script>
