<template>
  <transition name="slide-fade">
    <div v-if="sideWidgetData">
      <v-row>
        <v-col>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold mb-3">{{
                  sideWidgetData.name
              }}</v-list-item-title>
              <v-list-item-subtitle>
                Start Time:<span class="font-weight-bold float-right">
                  {{ new Date(sideWidgetData.start_time).toDateString() }}</span>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                End Time:
                <span class="font-weight-bold float-right">{{
                    new Date(sideWidgetData.end_time).toDateString()
                }}</span>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                Age Range:
                <span class="font-weight-bold float-right">{{ sideWidgetData.targeting.age_min }} -
                  {{ sideWidgetData.targeting.age_max }}</span>
              </v-list-item-subtitle>
              <!-- <v-list-item-subtitle>
                Budget Remaining:
                <span class="font-weight-bold float-right">{{
                  sideWidgetData.budget_remaining
                }}</span>
              </v-list-item-subtitle> -->
              <v-list-item-subtitle>
                Status:
                <span class="font-weight-bold float-right">{{
                    sideWidgetData.effective_status.replaceAll("_", " ")
                }}</span>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                Created on:
                <span class="font-weight-bold float-right">{{
                    new Date(sideWidgetData.created_time).toDateString()
                }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="mx-4">
          <v-btn small block class="custom-btn float-right"
            :to="{ name: 'facebook-ad-set', params: { id: sideWidgetData.id } }">
            More
            <v-icon right>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    sideWidgetData: {
      type: Object,
      default: null,
    },
  },
};
</script>